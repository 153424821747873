<template>
  <div class="product-dashboard"> 
    <div>
        <w-table
          :tableProperties="syndicationTable"
          :tableLoading="tableLoading"
        />
      <!-- </b-card> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TextInput from "../../widgets/TextInput.vue";
import DatePicker from "vue2-datepicker";
import TreeDropdown from "../../widgets/TreeDropdown";
import Dropdown from "../../widgets/Dropdown.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import { getSyndicationStatus } from '../../Service/SyndicationService';
import {
  getChannelList,
  getProductSorting,
  getProductTableList,
  getPerformanceOption,
} from "../../Service/DashboardService";
export default {
  props:["isGetData"],
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    DatePicker,
    "w-tree-dropdown": TreeDropdown,
    "w-table": TableWithCheckbox,
    Modal,
  },
  data() {
    return {
        syndicationTable: {
            items: [],
            fields: ['sku','title', 'standardPrice', 'salePrice', 'productSyncStatus', 'productImageSyncStatus', 'productPriceSyncStatus', 'productInventorySyncStatus']
        },
        tableLoading: false
    };
  },

  created() {
      this.getStatus();
  },

  methods: {
    getStatus() {
      this.tableLoading = true;
      getSyndicationStatus().then(res=> {
          this.tableLoading = false;
          console.log(res);
          this.syndicationTable.items = res.map(item => {
            return {
              ...item,
              productSyncStatus: item.bulletSync,
              productImageSyncStatus: item.imageSync,
              productPriceSyncStatus: item.priceSync,
              productInventorySyncStatus: item.inventorySync
            }
          });
      }).catch(err=> {
        console.log(err);
        this.tableLoading = false;
      });
    }
  },
};
</script>
<style scoped>
/* .dashboard-filter{
  position: absolute;
  z-index: 200;
} */
.dashboard-filter ::v-deep .card {
  border-radius: 5px;
  padding: 10px 0px;
}
.dashboard-filter ::v-deep .custom-select {
  height: auto;
}
/* .dashboard-filter ::v-deep .card-body {
  padding-top: 0;
} */
.performance-search-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-table ::v-deep .card-body {
  padding: 0 7px;
}
.product-table ::v-deep .card-body .userTable .card {
  margin-top: 0 !important;
}
.product-table ::v-deep .card-body .row {
  margin-bottom: 0 !important;
}
.product-table ::v-deep .theadClass {
  text-align: center;
}
.product-table ::v-deep .table thead tr th:first-child {
  text-align: left !important;
}
.product-table ::v-deep .table thead tr th:nth-child(7) {
  width: 9% !important;
}
.product-table ::v-deep .table thead tr th:nth-child(8) {
  width: 9% !important;
}
.product-table ::v-deep .table thead tr th:nth-child(9) {
  width: 7% !important;
}
.product-table ::v-deep .table tbody tr td {
  text-align: center !important;
}

.product-table ::v-deep .table tbody tr td:first-child {
  width: 25% !important;
  text-align: left !important;
}
/* .search-input ::v-deep .form-control {
  font-size: 14px;
} */
.performance-based ::v-deep .select_style .custom-select option:first-child {
  display: none;
}
/* .performance-search-group
  ::v-deep
  .select_style
  .custom-select
  option:first-child {
  display: none;
} */
.performance-search-group ::v-deep .form-control {
  height: 37px !important;
}
.performance-search-group ::v-deep .custom-select {
  height: 37px;
}
.mobile-filter {
  display: none;
}
.open-mobile-filter {
  display: none;
}
.product-dashboard ::v-deep .userTable tbody tr td:nth-child(1) {
    text-align: left !important;
  }
.product-dashboard ::v-deep .userTable tbody tr td {
    text-align: center;
  }
/* for responsive */
@media only screen and (min-width:768px) and (max-width:1200px){
  .product-dashboard ::v-deep .table-pagination{
    max-width: 100%;
    margin-left: auto;
  }
}
@media only screen and (max-width: 768px) {
  .product-dashboard ::v-deep .search-input{
    padding-right: 0;
  }
  .product-table ::v-deep .card-body .userTable {
    margin-top: 12px !important;
  }
  .product-dashboard {
    margin-top: 60px;
  }
  .date-picker ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  ::v-deep .mx-calendar {
    width: 100%;
  }
  ::v-deep .mx-calendar-content {
    width: 100%;
  }
  ::v-deep .mx-datepicker-popup {
    left: 0 !important;
  }

  /* .desktop-filter {
    display: none;
  } */
  .mobile-filter {
    display: block;
    /* margin-top: 73px; */
    margin-top: 0;
  }
  .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 222222;
  }
  /* .apply-filter {
    padding: 5px 12px;
    border: 1px solid;
    background: #fff;
    cursor: pointer;
    border-radius: 18px;
  } */
}

@media only screen and (max-width: 340px){
  .product-dashboard ::v-deep .table-pagination .page-link{
    min-width: 15px !important;
  }
}

.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
</style>
